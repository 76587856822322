import MainLayout from '../layouts/MainLayout.vue';
import GamesListLayout from '../layouts/GamesListLayout.vue';
export default function getRoutes({ Vue, store }) {
  const authenticateUser = (to, from, next) => {
    try {
      const blnIsUserLoggedIn = store.getters['blnIsUserLoggedIn'];
      console.log(blnIsUserLoggedIn);
      if (!blnIsUserLoggedIn) {
        console.log('redirecting');
        return next({
          name: 'SignIn',
        });
      }
    } catch (e) {
      console.log(e);
      return next({
        name: 'SignIn',
      });
    }
    next();
  };

  const routes = [
    {
      path: '/',
      name: '',
      component: MainLayout,
      children: [
        {
          path: '',
          name: 'Home',
          props: true,
          component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
        },

        {
          path: 'search',
          name: 'Search',
          component: () => import(/* webpackChunkName: "password" */ '../views/Search.vue'),
        },
        {
          path: 'time-slot',
          name: 'TimeSlot',
          component: () => import(/* webpackChunkName: "password" */ '../views/TimeSlot.vue'),
        },
        {
          path: 'details',
          name: 'Details',
          component: () => import(/* webpackChunkName: "password" */ '../views/Details.vue'),
        },
        {
          path: 'thank-you',
          name: 'ThankYou',
          component: () => import(/* webpackChunkName: "password" */ '../views/ThankYou.vue'),
        },
      ],
    },
  ];

  return routes;
}
