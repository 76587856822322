<style src="../node_modules/buefy/dist/buefy.min.css"></style>

<style src="./css/icomoon.css"></style>
<style src="./css/customstyle.min.css"></style>
<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.min.css");
</style>

<style src="../node_modules/vue-tel-input/dist/vue-tel-input.css"></style>

<template>
  <div id="app">
    <div id="myContainer" ref="myContainerRef"></div>
    <!-- <b-button label="Click    me" @click="handleClick"></b-button> -->
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import { getRouter } from "./router";
let router = getRouter({ store });
import axiosPlugin from "./plugins/axios";
import utilsPlugin from "./plugins/utils";
import io from "socket.io-client";

import VueTelInput from "vue-tel-input";
import "../node_modules/vue-tel-input/dist/vue-tel-input.css";

Vue.use(VueTelInput);

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import {
  faHouse,
  faUser,
  faCartShopping,
  faClose,
  faCircleCheck,
  faWallet,
  faCircleXmark,
  faGear,
  faCoins,
  faBell,
  faRightFromBracket,
  faShuffle,
  faPencil,
  faTrash,
  faSearch,
  faEye,
  faAngleLeft,
  faAngleRight,
  faEyeSlash,
  faCopy,
  faPlus,
  faTimes,
  faAngleDown,
  faMinus,
  faCamera,
  faSmile,
  faPlane,
  faHome,
  faClock,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faHouse,
  faUser,
  faCartShopping,
  faGear,
  faWallet,
  faCoins,
  faBell,
  faRightFromBracket,
  faShuffle,
  faPencil,
  faClose,
  faCircleCheck,
  faCircleXmark,
  faTrash,
  faSearch,
  faEye,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faEyeSlash,
  faCopy,
  faPlus,
  faMinus,
  faEnvelope,
  faFacebookF,
  faTimes,
  faGoogle,
  faCamera,
  faSmile,
  faPlane,
  faHome,
  faClock,
  faCalendar,
);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

import Buefy, { ConfigProgrammatic } from "../buefy";
import {
  Table,
  Input,
  Modal,
  Button,
  Autocomplete,
  Field,
  Dialog,
  Snackbar,
  Tabs,
  Datepicker,
} from "../buefy";

Vue.use(Buefy, {
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas",
});

ConfigProgrammatic.setOptions({
  defaultIconPack: "fas",
  defaultIconComponent: "font-awesome-icon",
});

import { i18n } from "./i18n";

Vue.use(axiosPlugin, { router, store, i18n });
Vue.use(utilsPlugin, { router, store, i18n });

Vue.use(Table);
Vue.use(Input);
Vue.use(Button);
Vue.use(Autocomplete);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Snackbar);
Vue.use(Tabs);

Vue.use(Modal);
Vue.use(Datepicker);
Vue.prototype.strLottoId = "";

import { mapGetters } from "vuex";

import "./vee-validate";
export default {
  name: "app",
  emits: ["stats", "signIn", "signUp"],

  props: {
    cid: {
      type: String,
      default: "ja",
    },

    bid: {
      type: String,
      default: "ja",
    },
    mode: {
      type: String,
      default: "",
    },
    lang: {
      type: String,
      default: "en",
    },
  },
  i18n,
  router,
  store,
  computed: {
    ...mapGetters([
      "blnIsUserLoggedIn",
      "strToken",
      "arrLottoConfigs",
      "objWalletStats",
      "objUser",
      "objUserProfile",
    ]),
  },
  created() {
    //console.log("the lang is " + this.lang);

    this.$i18n.locale = this.lang;

    window.addEventListener("changeLang", (event) => {
      //console.log(event);
      let strLang = event.detail;
      this.$i18n.locale = strLang;
    });

    this.setLottos();
  },
  beforeDestroy() {
    console.log("destroying");
  },
  async mounted() {
    // router.push("/home");

    Vue.prototype.$strClientId = this.cid;
    Vue.prototype.$strBrandingId = this.bid;

    Vue.prototype.$strMode = this.mode;

    if (this.mode == "gamesOnly") {
      //console.log("mode is", this.mode);
      Vue.prototype.$blnIsGamesOnly = true;
      Vue.prototype.$strLoadPath = location.hostname;
      this.$router.replace({ name: "GamesList" });
    }
    if (this.blnIsUserLoggedIn) {
      this.setAxiosToken(this.strToken);
      this.ping();
    }
    Vue.prototype.$root = this.$root;
    this.$store.commit("emitState");

    window.setTimeout(() => {
      this.$store.commit("emitState");
    }, 1000);

    //this.initStoreLottos();
    await this.load();
    this.loadRecaptcha();

    //document.getElementById("someOtherDiv").appendChild(photo);

    // const socket = io("http://localhost:3000");
    // socket.emit("chat message", "wow");

    // socket.on("chat message", function (msg) {
    //   alert("Received message");
    // });
  },
  methods: {
    async loadUserInfo() {
      let p1 = this.$myAxios.get("/u/wallet-stats");
      let p2 = this.$myAxios.get("/u/me");
      let arr = await Promise.all([p1, p2]);

      //console.log(arr);

      let objResponse1 = arr[0];
      let objResponse2 = arr[1];

      if (objResponse1.blnOK) {
        let objWalletStats = objResponse1.obj;
        //console.log("Setting wallet stats");

        this.$store.commit("setWalletStats", objWalletStats);
      } else {
        //console.log(objResponse1.strMessage);
      }

      if (objResponse2.blnOK) {
        let objUser = objResponse2.obj;
        //console.log("Setting user profile");
        this.$store.commit("setUserProfile", objUser);
      } else {
        //console.log(objResponse2.strMessage);
      }
    },
    async emitUserInfo() {
      if (!this.blnIsUserLoggedIn) {
        console.log("user not logged in");
        return;
      }

      try {
        await this.loadUserInfo();
      } catch (e) {
        console.log(e);
      }

      let strBalance = "";
      let strPendingWithdrwal = "";
      let strMobile = "";
      let strAvatarUrl = "";

      if (this.objUserProfile) {
        strMobile = this.objUserProfile.mobile;
        strAvatarUrl = this.$utils.getAvatarFileName(this.objUserProfile);
      }

      if (this.objWalletStats) {
        strBalance = this.objWalletStats.fltEffectiveBalance;
      }

      let objUserInfo = {
        strBalance,
        strMobile,
        strPendingWithdrwal,
        strAvatarUrl,
      };

      //console.log(objUserInfo);

      this.$utils.emitWCEvent2(this, "userInfo", objUserInfo);
    },

    async load() {
      // this.emitUserInfo();
      // let arr = [this.loadLottosConfigs(), this.loadSettings()];
      // let arrResponses = await Promise.all(arr);

      // let objConfigResponse = arrResponses[0];
      // let objSettingsResponse = arrResponses[1];

      // //alert(objSettingsResponse);

      // if (objConfigResponse.blnOK) {
      //   let arr = objConfigResponse.arr;
      //   for (let obj of arr) {
      //     obj.strCountdown = "xx";
      //   }

      //   this.$store.commit("setLottoConfigs", arr);
      // }

      // if (objSettingsResponse.blnOK) {
      //   let { obj } = objSettingsResponse;
      //   this.$store.commit("setBrandingSettings", obj);
      // }
    },
    setLottos() {
      this.$store.commit("setLottos", [
        { strId: "pick3", strName: "Pick 3", strLogoName: "pick3-logo.png" },
        { strId: "pick4", strName: "Pick 4", strLogoName: "pick4-logo.png" },
      ]);
    },
    async loadLottosConfigs() {
      let objRequest = {
        arrLottoIds: ["pick3", "pick4"],
      };
      return this.$myAxios.post("/lottos-configs", objRequest);
    },

    async loadSettings() {
      return this.$myAxios.get("/lb-settings");
    },
    initStoreLottos() {
      return;
    },
    loadRecaptcha() {
      if (this.$strBrandingId != "888drc" && this.$strBrandingId != "hs") {
        return;
      }
      let strSiteKey = this.$utils.RECAPTCHA_SITE_KEYS[this.$strBrandingId];
      let script = document.createElement("script");
      script.id = "theid";
      script.src = `https://www.google.com/recaptcha/api.js?render=${strSiteKey}`;
      script.async = true;
      script.defer = true;

      let objApp = this.$refs["myContainerRef"];

      //console.log(this.$refs);

      objApp.appendChild(script);
    },

    async ping() {
      try {
        let objResponse = await this.$myAxios.get("/u/ping");
        if (!objResponse.blnOK) {
          this.$store.commit("logout");
          this.$utils.emitWCEvent(this, "signIn");
        }
      } catch (e) {
        console.log(e);
      }
    },
    handleClick() {
      grecaptcha.ready(function () {
        grecaptcha
          .execute(RECAPTCHA_SITE_KEY, {
            action: "submit",
          })
          .then(function (token) {
            // Add your logic to submit to your backend server here.

            alert("oy2" + token);
          });
      });

      //console.log("emitting");
      //this event can be listented to in the holding page
      //by attaching an eventListener to the WC
      //this.$emit("signIn", { name: "Data" });

      return;

      //we can also update any element on the holding
      //page directly
      // let objDiv = document.getElementById("myDiv");
      // try {
      //   objDiv.innerHTML = new Date();
      // } catch (e) {
      //   console.log(e);
      // }
    },
  },
};
</script>
<style scoped>
.grecaptcha-badge {
  visibility: hidden;
}
</style>
