import en from "vee-validate/dist/locale/en.json";

export default {
  HOME: "Home",
  BUY_NOW: "Buy Now",
  PLAY: "Play",
  Note: "Note",
  WELCOME: "Welcome to ",
  PICK_NUMBERS: "Pick numbers",
  PROCEED_CART: "Proceed to Cart",
  PROCEED_CHECKOUT: "Proceed to Checkout",
  CART_TOTAL: "Cart Total",
  BACK: "Back",
  RANDOMIZE_ALL: "Randomize all",
  CLEAR_ALL: "Clear All",
  ADD_LINE: "Add Line",
  LINE_NO: "Line #",
  MY_SHOPPING_CART: "My Shopping cart",
  PENCIL: "Pencil",
  CHECKOUT_MSG_1: "By clicking on the 'Checkout' Button, you agree to our ",
  CHECKOUT_MSG_2: "Rules and Terms & Conditions",
  CHECKOUT_MSG_3:
    "All transactions are charged in USD and transaction fees apply. Prices displayed in other currencies are for informative purposes only and are converted according to actual exchange rates.",
  DELETE: "Delete",
  PRICE: "Price",
  PAY: "Pay",
  CHECKOUT: "Checkout",
  MY_WALLET: "My Wallet",
  WALLET: "Wallet",
  LOTTO: "Lotto",
  AVIATOR: "Aviator",
  AMOUNT: "Amount",
  TRANSACTIONS: "Transactions",
  ID: "Id",
  ID_TRANSACTION: "Id Transaction",
  DATE: "Date",
  TYPE: "Type",
  STATUS: "Status",
  CREDIT: "Credit",
  EDIT: "Edit",
  FIELD_REQUIRED: "This field is required",

  SUBMIT: " Submit",
  LOTTO_GAMES: "Lotto Games",
  WELCOME_TO_888: "Welcome to 888 Lotto",
  BET_SLIP: "Bet Slip",
  LOTTO_RESULTS: "Lotto Results",

  SUBMIT_ON_FORGOT_PASSWORD: "Submit",
  INSUFFICIENT_FUNDS: "Insufficient Funds",
  INSUFFICIENT_FUNDS_MSG:
    "You need to make a deposit to your player account to complete this purchase.",
  PRICE_AMOUNT: "Price Amount",
  CANCELED: "Canceled",
  PAY_AMOUNT: "Pay Amount",
  ACCOUNT_EMAIL_NOT_FOUND: "Error: Account with email does not exist",
  DEPOSIT: "Deposit",
  DEPOSIT_NOW: "Deposit Now",
  PENDING: "Pending",
  WITHDRAW_AMOUNT: "Withdraw Amount",
  COPY_THE_PAY_WALLET_ADDRESS: "You can also copy the pay wallet address here",
  COPY: "Copy",
  DONE: "Done",
  WITHDRAW_AMOUNT_MSG: "Please enter the amount you wish to withdraw",
  WALLET_ADDRESS_MSG: "Please enter your wallet address here",
  WITHDRAW: "Withdraw",
  MY_TICKETS: "My Tickets",
  DRAW_DATE: "Draw Date",
  BET_ID: "Bet Id",
  COST: "Cost",
  WINNINGS: "Winnings",
  NEXT: "Next",
  TICKET: "Ticket",
  TICKETS: "Tickets",
  TICKET_NUMBERS: "Ticket Numbers",

  CREATE_ACCOUNT: "Create Account",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  USER_NAME: "User Name",

  ENTERADDRESS: "Enter Address",
  POSTCODE: "Enter Post Code",
  CITY: "Enter City ",

  DEPOSIT_AMOUNT_MSG:
    "You can simply choose one of the amounts below or enter your amount using the blue box.",
  WITHDRAW_PRIZES: "Withdrawal of Prizes",

  SIGNUP_DATA:
    " Your Personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.",

  LOGIN: "Login",
  LOGIN_ALT: "Login",
  DETAIL: "Detail",
  NEXT_PAGE: "Next Page",
  PREVIOUS_PAGE: "Previous Page",
  PAGE: "Page",
  CURRENT_PAGE: "Current Page",
  LOGOUT: "Logout",
  NO_ITEMS_IN_CART: "There are no items in your cart",
  YOUR: "Your",
  CART_EMPTY:
    "There are no lines in your cart. Please use the 'Add Line' button below to add.",
  PASSWORD_RESET_EMAIL: "We have sent an email with the password reset link.",
  PROFILE: "Profile",
  EDIT_PROFILE: "Edit Profile",
  BET_PLACED_MSG: "Your order has been placed!",
  INCREASE_YOUR_CHANCES:
    "Increase your chances of winning $30,000 by buying more tickets",
  ONE_TICKET_ONE_CHANCE: "One ticket is one chance",
  ALL_DEPOSITS_SUBJECT_NETWORK_FEES:
    "All deposits are subject to the Tron network fees. This fee is the same for any amount. Send more to save on network fees (Recommended to send 10 USD to save on fees).",
  GENERATED_WALLET_ADDRESS_VALID_24:
    "The generated wallet address is only valid for 24hrs. After 24hrs generate a new deposit address to send funds.",
  WITHDRAWALS_TAKE_72_HRS:
    "Withdrawals may take up to 72hrs to process. Only use a USD on the TRC20 network address for withdrawals, or funds will be lost. You can only withdraw 50 USD or over.",
  BE_CAREFUL_TO_ENTER_THE_ADDRESS:
    "Be careful to enter the address only for USD deposit and only in the TRC20 network. Otherwise, your money will be lost in the cryptocurrency network. After authentication, the payment is made within 3 working days.",
  PLAY_AGAIN: "Play Again!",
  ORDER: "Order",
  ORDER_CONFIRMATION: "Order Confirmation",
  HOW_TO_ADD_CREDIT: "How to Add Credit",
  WATCH_ADD_CREDIT_VIDEO_MSG:
    "Please watch how to add credit video first to make sure the payment is Done correctly.",
  WITHDRAWAL_REQUEST_SUCCESS:
    "Your withdrawal request has been received. It will be processed shortly.",
  WITHDRAWAL_REQUEST_ERROR:
    "There was an error while trying to place the withdrawal request.",
  CREATE_ANOTHER_WITHDRAWAL:
    "To create another withdrawal request, please press the following button.",
  WITHDRAWAL_OF_PRIZES: "Withdrawal of Prizes",
  NUMBERS_NOT_SELECTED_MSG:
    "You Have not selected your numbers. Please click here to select them.",
  ENTER_A_NUMBER: "Enter a number",
  VALUE_MUST_BE_LESS_THAN: "Value must be less than or equal",
  GAME: "Game",
  PLACED_DATE: "Placed Date",
  DATA_NOT_AVAILABLE: "Data is not available at this time.",
  COMBINATION: "Combination:",
  SINGLE_LOTTERY_BET_AMOUNT: "Single Lottery Bet Amount",
  TOTAL_WINNINGS: "Total Winnings",
  TICKET_NUMBER: "Ticket Number",
  DRAW_NUMBER: "Draw Number",
  SELLER: "Seller",
  PURCHASE_DATE: "Purchase Date",
  RESULT: "Result",
  MY_NUMBERS: "My Numbers",
  LOADING: "Loading...",
  TOTAL_COST: "Total Cost",
  TOTAL: "Total",

  PLACED: "Placed",
  LOST: "Lost",
  WON: "Won",

  ERROR_EMAIL_PASSWORD: "Error: Incorrect user Email/Password",
  OK: "Ok",
  DEBIT: "Debit",
  LOTTO_TICKET: "Lotto Ticket",
  REFUND: "Refund",
  WINNING: "Winning",
  SUCCESSFUL: "Successful",
  FAILED: "Failed",
  GET_GUIDANCE_HOW_TO_CREDIT:
    "Please watch how to add credit video first to make sure the payment is done correctly",
  GET_GUIDANCE_HOW_TO_WITHDRAW: "Get guidance on how to WITHDRAW.",
  DEPOSIT_WARNING:
    "WARNING: Your money will be lost if you Don't send( USD) and in (TRC20) network. If you don’t have a wallet you could use others for deposits. Always consider to deposit more for transaction fees. Please Watch the video to learn more. ",
  EMAIL_ALREADY_TAKEN: "This email is already taken.",
  fields: {
    email: "EMAIL",
    password: "PASSWORD",
  },

  SELECT_SIX_NUMBERS: "Please Select Six Numbers Per Ticket",
  ACCOUNT_CREATED_MSG: "Your account has been created.",
  ACCOUNT_CREATED_INST: "",
  ACCOUNT_CREATED_MSG_EMAIL:
    "Your account has been created and we have sent an email to verify your account.",
  ACCOUNT_CREATED_INST_EMAIL:
    "Please follow the instructions in the email to verify.",
  CONFIRM_PASSWORD: "Confirm Password",
  TOKEN_EXPIRED: "Your Token has expired. Please try again.",
  TOKEN_EXPIRED_2: "Reset Password",
  WITHDRAWABLE: "Withdrawable",
  NOT_ENOUGH_BALANCE:
    "Your wallet does not have the balance you are requesting to withdraw",

  SIGN_IN: "Sign In",
  SMS_REGISTRATION: "SMS Registration",
  EMAIL_REGISTRATION: "Email Registration",
  MOBILE_NUMBER: "Mobile Number",

  OR: "OR",
  NOT_REGISTERED_YET: "Not registered yet?",
  CREATE_ACCOUNT_AND_START: "Create a free account and start winning ",
  START_WINNING: "and start winning.",
  CREATE_ACCOUNT_AND_START_EARNING: "Create an account now and start earning.",
  REGISTER: "Register",

  EMAIL: "Email",
  SIGN_UP: "Sign Up",

  CONTINUE: "Continue",
  SIGNUP_TC:
    "I agree to the Terms and Conditions  of the website. I confirm that I am over 16 years old and agree to receive further communications from this website via email and phone number. ",

  ALREADY_REGISTERED: "Already have an account?",
  LOGIN_HERE: "Login Here.",
  PLEASE_ENTER_THE_ADDRESS_OR_MOBILE:
    "Please enter the address or mobile registration number you used to sign up",

  SEND_RESET_LINK: "Send reset link",
  REMOVE_THE_LEADING:
    "Remove the leading 0 from the start of your mobil number",
  IM_NOT_ROBOT: "Im not robot",
  SEND_OTP: "Send OTP",

  VERIFY_OTP: "Verify OTP",
  ENTER_CODE: "Enter code",
  VERIFY: "Verify",
  THE_OTP_IS_ONE_TIME:
    "The OTP is one time password that allows you to set a new one",
  REMEMBER_ME: "Remember me",

  RESET_PASSWORD: "Reset Password",
  SET_PASSWORD: "Set Password",
  INTRODUCE_NEW_PASSWORD: "Please enter your new password",
  REPEAT_PASSWORD: "Repeat Password",
  FOURPINCODE: "Enter a 4-digit secret code",
  REPEATPIN: "Repeat secret code",
  NAME: "Name",
  FAMILY_NAME: "Family name",
  PLEASE_MAKE_A_PAYMENT_USING_QR:
    "Please make a payment using the QR code bellow",

  FORGOT_PASSWORD: "Forgot Password",
  EMAIL_ADDRESS: "Email Address",
  AN_OTP_ONE_TIME_PASSWORD:
    "An OTP is one time password that allows you to set a new one",
  YOU_HAVE_LOGGED_OUT: "You have logged out. Please",
  AGAIN: "again.",
  ACCOUNT: "Account",
  NOTIFICATIONS: "Notifications",
  CURRENCY: "Select Currency",
  SELECT_OPERATOR: "Select Operator",
  SELECT_CURRENCY: "Select Currency",

  VERIFY_EMAIL: "Verify Email",
  PLEASE_WAIT_ACCOUNT_VERIFYING: "Please wait. We are verifying your account",
  YOUR_ACCOUNT_HAS_BEEN_VERIFIED:
    "Your account has been verified. You will be signed in  automatically in 5 seconds.",

  SORRY_YOUR_ACCOUNT_HAS_FAILED: "Sorry your account verification has failed",
  IF_YOUR_ARE_NOT_AUTO_SIGNED_IN: "if you are not auto signed in.",
  ENTER_MOBILE_NUMBER_WITHOUT_ZERO:
    "Note: Enter your mobile phone number without the leading zero.",

  CLICK_HERE: "Click here",
  USERNAME: "Username",
  PASSWORDS_DONT_MATCH: "Passwords don't match",
  ERROR_MOBILE_PASSWORD: "Mobile or Password is wrong",
  ENTER_PHONE_NUMBER: "Enter your phone number",
  USER_NOT_FOUND: "Account not found",
  WALLET_ADDRESS_COPIED: "Wallet Address copied",
  COPIED: "copied",
  MOBILE_ALREADY_TAKEN: "This mobile number is already registered",
  REPEAT_EMAIL: "Repeat Email",
  REPEAT_MOBILE_NUMBER: "Repeat mobile number",
  EMAILS_DONT_MATCH: "Emails don't match",
  MOBILES_DONT_MATCH: "Mobiles don't match",
  ENTER_EMAIL: "Please enter email",
  PASSWORD_CHANGED: "Password Changed",

  E_VOUCHER_NUMBER: "e-Voucher Number",
  E_VOUCHER_ACTIVATION_CODE: "e-Voucher Activation Code",

  SEARCH_CRYPTO_EXCHANGE_OFFICES: "Search crypto exchange offices",
  SEARCH_PERFECT_MONEY_DEALERS: "Search perfect money dealers",
  SUCCESSFUL_EV_NUMBER: "Successful",
  WATCH_THIS_VIDEO_HOW_TO_USE_PERFECT_MONEY:
    "Watch this video to learn how to use perfect money to deposit",

  PAY_WITH_PERFECT_MONEY: "Pay With Perfect Money",
  PAY_WITH_CRYPTO: "Pay With Crypto",
  INVALID_EV_NUMBER: "Invalid ev-number",
  TICKET_ID: "Ticket ID",
  WITHDRAW_AMOUNT_LIMIT: "Minimum withdraw value is 50 USD",
  ONLY_AMOUNT_ABOVE_4USD: " Only for amounts above USD 4",

  /*jackpot-aruba-translation-start*/
  WELCOME_TO_JACKPOTARUBA: "Welcome to Jackpot Aruba",
  BIRTHDATE: "Birthdate",
  DOB: "Date of Birth",

  ADDRESS: "Address",
  STREETADDRESS: "Street Address",
  CITYORTOWN: "City/Town",
  PROVINCE: "Province",

  MY_BETS: "My Bets",
  MY_BETS_LOTTO: "My Bets Lotto",
  ALL_RESULTS: "All Results",

  PLAY_NOW: "Play Now!",
  DRAW_EVERY_5_MIN: "Draw every 5 mins",
  COMING_SOON: "Coming soon",
  LATEST_LOTTO_RESULTS: "Latest Lotto Results",
  SELECT_DRAW_DATE: "Select draw date",
  WINNING_NUMBERS: "Winning Numbers",

  validations: en,

  MIN: "Min",
  SEC: "Sec",
  MINUTES: "Minutes",
  SECONDS: "Seconds",
  LATEST_LOTTERY_RESULTS: "Latest Lottery results",

  DRAW_EVERY_5_MINS: "Draw Every 5 Mins",
  PLEASE_ENTER_YOUR_MOBILE:
    "Please enter your mobile and we'll send you a code to reset your password.",
  BACK_TO_LOGIN: "Back to Login",

  SELECT_DRAW_DATE: "Select Draw Date",
  SELECT_LOTTERY: "Select Lottery",
  ALL_RESULTS: "All Results",
  DRAW_NUMBER: "Draw Number",
  WINNING_NUMBERS: "Winning Numbers",
  PERSONAL_DATA: "Personal Data",
  SELECT_LOTTERY_PICK3: "Select Lottery",

  NUMBER_OF_DRAWS: "Number of draws",
  QUICK_PICK: "Quick Pick",
  CLEAR: "Clear",
  LINE: "Line",
  LINES: "Lines",

  ADD_ANOTHER_LINE: "Add another line",
  PHONE_NUMBER: "Phone Number",
  PASSWORD: " Password",
  DRAW_CLOSES_IN: "Draw closes in",

  ERROR_INVALID_PARAMETER: "ERROR-Invalid parameter",
  HOW_MAY_WE_HELP: "How may we help?",
  WHEN_YOU_SEND_200FC: "When you spend 200FC",

  CHECK_THE_LATEST_RESULTS:
    "Check the latest results to see if you have won! Results are posted directly after each draw has taken place. Login to your Jackpot Aruba account for more draw details, compare your numbers and see ",
  I_AGREE_TO_THE_END:
    "I agree to the  and   of the website. I confirm that I am over 16 years old and agree to receive further communications from this website via email and phone number. ",
  PLEASE_ENTER_OTP1: "Please enter the OTP sent to ",
  PLEASE_ENTER_OTP2: " to verify your account.",
  SELECT_STAKE: "Select your stake",
  NUMBER_DRAWS: "Number of Draws",
  UPLOAD_NOW: "Upload now",
  UPLOAD: "Upload",
  UPLOAD_AVATAR: "Upload your Avatar",
  PAYMENT_AUTH_REQ_MSG:
    "You will get a payment authorization request from your mobile network operator at this number:",

  KYC_DOCS: "KYC Docs",
  ACCOUNT_ALREADY_EXISTS: "Account already exists",
  CONTACT_SUPPORT_PERSONAL_DATA:
    "Please contact support if you wish to change your personal data.",
  DRAW_EVENT_ON: "Draw event on",
  DRAWS: "Draws",
  REMOVE_DEPOSIT_TEXT:
    "To remove deposit restrictions on your account we are legally required to request the above listed KYC documents. Please contact our support for assistance if required.",
  SUPPORT: "Support",
  IF_EXISTS_SMS_SENT: "If your mobile is registered, an SMS has been sent",
  NO_DRAWS_FOUND: "No draws found.",
  ENTER_NUMBER_WITHOUT_ZERO: "Please enter your phone number without the zero",

  EDIT_TEXT: "Edit",
  VIEW_TEXT: "View",
  SUBMITTED: "Submitted",
  GENDER_TEXT: "Gender",
  UPDATE_PROFILE: "Update Profile",
  CLICK_TO_UPLOAD: "Click to upload",

  WEBCAM_TEXT: "Webcam photo",
  PROOF_OF_ADDRESS: "Proof of Address",
  SOURCE_OF_FUNDS: "Source of funds",
  CREDIT_CARD: "Credit Card",
  ID_AND_PASSPORT: "ID or Passport",
  UPDATE_YOUR_IMAGE: "Upload your image",

  UPLOAD_ID_PASSPORT: "Upload ID or Passport",
  UPLOAD_WEBCAM_PHOTO: "Upload Webcam photo",

  UPLOAD_PROOF_ADDRESS: "Upload Proof of Address",
  UPLOAD_SOURCE_OF_FUNDS: "Source of funds",
  UPLOAD_CC_FRONT: "Upload Credit Card Front",
  UPLOAD_CC_BACK: "Upload Credit Card Back",

  FILE_UPLOADED: "File Uploaded",

  MALE_TEXT: "Male",
  FEMALE_TEXT: "Female",
  OTHERS_TEXT: "Others",
  SELECT_DATE: "Select a date",
  NO_OPERATOR_AVAILABLE:
    "We are sorry, no operators are available at the moment",

  DRC_DEPOSIT_LIMIT_CDF:
    "Deposit between 500 FC minimum and 1,000,000 FC maximum",

  DRC_WITHDRAW_LIMIT_CDF:
    "Withdraw between 2000 FC minimum and 1,000,000 FC maximum ",
  DEPOSIT_AMOUNT: "Deposit Amount",
  PROMOTIONS: "Promotions",
  HOW_TO_PLAY: "How to Play",
  JA_DEPOSIT_LIMIT: "Minimum Deposit is 10 USD",
  PROCESSING_PAYMENT: "Processing Payment",

  WELCOME_TO_HOTSPOTBET: "Welcome to Hot Spot Bet",
  NO_DATA_FOUND: "No Data found",
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_CONDITION: "Terms and conditions",
  AND_TEXT: "and",
  GOOGLE_APPLY: "of Google apply.",

  AVIATOR_HEADING: "Aviator Message",
  CLAIM_FREE_AVIATOR: "To claim you Aviator bonus, you have to make a deposit first",
  YOU_HAVE_NOT_MADE_ANY_DEPOSIT: "You have not made any deposit yet.",
  DEPOSIT_PAGE: "Deposit page",
  ENTER_STAKE_AMOUNT: "Enter your stake amount and click Deposit",
};
