import dayjs from 'dayjs';
import axios from 'axios';
import utc from 'dayjs/plugin/utc';
import { faLeftRight } from '@fortawesome/free-solid-svg-icons';
dayjs.extend(utc);
const utils = {
  strVersionNo: '1.6.16',
  formatDateTime(strDateTime) {
    return dayjs(strDateTime).format('YYYY-MM-DD H:mm a');
  },
  formatDate(strDate) {
    return dayjs(strDate).format('DD-MM-YYYY');
  },
  emitWCEvent(ref, strEvent) {
    //console.log('Emitting event', strEvent)
    //ref is this
    ref.$root.$children[0].$emit(strEvent, { name: 'Data' });
  },
  emitWCEvent2($root, strEvent, objData) {
    //ref is this
    $root.$children[0].$emit(strEvent, objData);
  },
  emitUserInfo($root, objUser = null, objWalletStats = null) {
    let strMobileNumber = null;
    if (objUser) {
      strMobileNumber = objUser.c_dial_code;
    }
    let fltWalletBalance = null;
    if (objWalletStats) {
      fltWalletBalance = 1234;
    }

    this.emitWCEvent2($root, 'userInfo', {
      strMobileNumber,
      fltWalletBalance,
    });
  },

  getRandomNumbers(intNumNumbers, intMaxNumber) {
    let array = Array(intMaxNumber)
      .fill()
      .map((_, index) => index + 1);

    var tmp,
      current,
      top = array.length;

    if (top)
      while (--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
      }
    array = array.slice(0, intNumNumbers);

    return array;
  },
  showSuccessNotice() {},
  async getLottoConfig(strLottoId) {
    let objRequest = {
      arrLottoIds: ['pick3', 'pick4'],
    };
    let objResponse = await axios.post('/lottos-configs', objRequest);
    console.log(objResponse);

    return objResponse;
  },
  getMinDoBDate() {
    let obj18YearsAgo = dayjs().subtract(18, 'year');
    return obj18YearsAgo.toDate();
  },
  formatDateTimeUTC(strDateTime) {
    return dayjs(strDateTime).utc().format('YYYY-MM-DD H:mm a');
  },
  formatMoney(fltAmount, strCurrency) {
    fltAmount = parseFloat(fltAmount);

    if (isNaN(fltAmount)) {
      return 0;
    }
    if (strCurrency == 'CDF') {
      return Math.round(fltAmount);
    }
    return fltAmount;
  },

  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
};

export default utils;
