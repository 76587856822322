// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/icomoon.eot?dkcz9a", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/icomoon.ttf?dkcz9a", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/icomoon.woff?dkcz9a", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/icomoon.svg?dkcz9a", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:icomoon;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");font-weight:400;font-style:normal;font-display:block}[class*=\" icon-\"],[class^=icon-]{font-family:icomoon!important;speak:never;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon-cancel:before,.icon-close:before{content:\"\\e903\"}.icon-checkmark:before,.icon-tick:before{content:\"\\e90c\"}.icon-pencil:before{content:\"\\e90b\"}.icon-close1:before,.icon-remove:before,.icon-times:before{content:\"\\e90a\"}.icon-random:before{content:\"\\e909\"}.icon-life-bouy:before{content:\"\\e908\"}.icon-dollar:before,.icon-usd:before{content:\"\\e900\"}.icon-user:before{content:\"\\e901\"}.icon-trash:before{content:\"\\e902\"}.icon-bell:before{content:\"\\e904\"}.icon-credit-card:before{content:\"\\e905\"}.icon-eye:before{content:\"\\e906\"}.icon-home:before{content:\"\\e907\"}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
